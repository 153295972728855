import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

import { Button, ListingLabel, NamedLink } from '../../../components';

import { filters, goalsLabels } from '../../../marketplace-custom-config';
import { createSlug } from '../../../util/urlHelpers';

import css from './MultipleEnquiryForm.module.css';

const MAX_CHARACTER_COUNT = 250;

const MultipleEnquiryForm = props => {
  const { validators, selectedListingIds, setSelectedListingIds, currentUser } = props;
  const { selectedWorktripp, worktrippListings } = useSelector(state => state.Worktripp);

  const validateTextarea = value => {
    if (value) {
      const words = value.trim().split(/\s+/);
      if (value.length > MAX_CHARACTER_COUNT) {
        return 'Exceeded the maximum word count (MAX_CHARACTER_COUNT words)';
      }
    }

    return undefined; // No validation errors
  };

  const notContactedListings = useMemo(() => {
    return selectedWorktripp.listings.filter(
      worktrippListing => !worktrippListing.tx_id || worktrippListing.tx_id === 'NOT CONTACTED'
    );
  }, [selectedWorktripp.listings]);

  const allListingsContacted = useMemo(() => {
    return notContactedListings.length === 0;
  }, [notContactedListings]);

  const selectAllIsChecked = useMemo(() => {
    if (notContactedListings.length === 0) {
      return false;
    }
    return notContactedListings.every(listing => selectedListingIds.includes(listing.listing_id));
  }, [notContactedListings, selectedListingIds]);

  const onClickSelect = (value, listingId) => {
    let newSelectedListingIds = value
      ? [...selectedListingIds, listingId]
      : selectedListingIds.filter(lid => lid !== listingId);

    setSelectedListingIds(newSelectedListingIds);
  };

  const onClickSelectAll = () => {
    let newSelectedListingIds =
      selectedListingIds.length > 0 ? [] : notContactedListings.map(listing => listing.listing_id);
    setSelectedListingIds(newSelectedListingIds);
  };

  const renderMessageDetails = () => {
    return (
      <>
        <label className={css.selectAllLabel}>
          <Field
            name="selectAll"
            component="input"
            type="checkbox"
            className={css.selectAllRadio}
            checked={selectAllIsChecked}
            disabled={allListingsContacted}
            onClick={onClickSelectAll}
          />
          Select all
        </label>

        <Field
          name="message"
          render={({ input, meta }) => {
            return (
              <div>
                <label htmlFor={'Customise your message'} className={css.textAreaTitle}>
                  Customise your message{' '}
                </label>
                <textarea
                  {...input}
                  maxLength={MAX_CHARACTER_COUNT}
                  className={css.textAreaStyle}
                />
                <div className={css.textAreaMaxText}>
                  {input.value.length} / {MAX_CHARACTER_COUNT}
                </div>
                {meta.touched && meta.error && <span>{meta.error}</span>}
              </div>
            );
          }}
          validate={validateTextarea()}
        />
        <WorktrippDetails
          css={css}
          currentUser={currentUser}
          selectedWorktripp={selectedWorktripp}
        />
      </>
    );
  };

  return (
    <div>
      {selectedWorktripp?.listings?.map(({ listing_id }) => {
        const listing = worktrippListings[listing_id];
        if (!listing) {
          return;
        }
        const transactionId = selectedWorktripp?.listings?.find(
          worktrippListing => worktrippListing.listing_id === listing.id.uuid
        )?.tx_id;

        return (
          <ListingWithCheckBox
            key={listing.id.uuid}
            listing={listing}
            checked={selectedListingIds.includes(listing.id.uuid)}
            transactionId={transactionId}
            onChange={value => onClickSelect(value, listing.id.uuid)}
          />
        );
      })}

      {allListingsContacted ? (
        <div className={css.allContactedContainer}>
          <h2>All listings contacted</h2>
          <span>Continue your chats or browse more listings</span>
        </div>
      ) : (
        renderMessageDetails()
      )}
    </div>
  );
};

const WorktrippDetails = ({ css, currentUser, selectedWorktripp }) => {
  if (!selectedWorktripp) {
    return <div />;
  }

  const { name, noOfParticipants, goals, startDate, endDate } = selectedWorktripp;
  const companyName = currentUser?.attributes?.profile.publicData?.organisationName;
  const goalTitles = goals?.map(goal => goalsLabels.find(e => e.key === goal)?.title)?.join(', ');

  return (
    <div>
      {name && (
        <div className={css.labelText}>
          WorkTripp: <div className={css.labelSub}> {name}</div>
        </div>
      )}
      {companyName && (
        <div className={css.labelText}>
          Company: <div className={css.labelSub}> {companyName}</div>
        </div>
      )}
      <div className={css.labelText}>
        Goals: <div className={css.labelSub}> {goalTitles}</div>
      </div>
      <div className={css.labelText}>
        Team size:{' '}
        <div className={css.labelSub}>
          {noOfParticipants} participant{noOfParticipants === 1 ? '' : 's'}
        </div>
      </div>
      <div className={css.labelText}>
        Dates:{' '}
        <div className={css.labelSub}>
          {new Date(startDate).toLocaleDateString('en-GB')} -{' '}
          {new Date(endDate).toLocaleDateString('en-GB')}
        </div>
      </div>
    </div>
  );
};

const ListingWithCheckBox = ({ listing, transactionId, checked, onChange, disabled }) => {
  const { attributes, images, id } = listing;
  const { title, publicData } = attributes || {};
  const { listingType, serviceType } = publicData || {};
  const listingImage = images[0]?.attributes?.variants['square-small']?.url || NoImageDefault;

  const slug = createSlug(title);
  const hasTransactionId = transactionId && transactionId !== 'NOT CONTACTED';

  const onClickChat = () => {
    if (!hasTransactionId) {
      return;
    }
    window.location.assign(`/order/${transactionId}/details`);
  };

  return (
    <div className={css.checkboxContainer}>
      <div className={css.checkboxContent}>
        <img src={listingImage} className={css.listingImage} />

        <div className={css.informationContainer}>
          <NamedLink
            name="ListingPage"
            params={{ id: id.uuid, slug, listingType }}
            style={{ display: 'flex', flex: 1, padding: 0 }}
          >
            <span className={css.titleText}>{title}</span>
          </NamedLink>
          <ListingLabel listingType={listingType} serviceType={serviceType} />
        </div>
      </div>

      <div style={{ minWidth: 65 }}>
        {hasTransactionId ? (
          <Button className={css.viewChatButton} onClick={onClickChat}>
            Chat
          </Button>
        ) : (
          <Field
            type="checkbox"
            component="input"
            className={css.checkboxLabel}
            name={title}
            id={title}
            disabled={disabled}
            render={<input type="checkbox" value={title} disabled={disabled} />}
            checked={checked}
            onChange={() => onChange?.(!checked)}
          />
        )}
      </div>
    </div>
  );
};

export default MultipleEnquiryForm;
