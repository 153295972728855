import React, { useState } from 'react';
import css from './ListingTypeHeader.module.css';
import classNames from 'classnames';

const ListingTypeHeader = props => {
  const { display, switchPage, listingType, selectedListingTab, icon, total } = props;

  return (
    <div style={{ paddingRight: '1.5rem' }}>
      <div
        className={
          selectedListingTab == listingType ? classNames(css.root, css.selected) : css.root
        }
        onClick={() => switchPage(listingType)}
      >
        {icon && <img src={icon} className={css.icon} />}
        <span>
          {display} {total && `(${total})`}
        </span>
      </div>
    </div>
  );
};

export default ListingTypeHeader;
