import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components';
import { filters, goalsLabels } from '../../../marketplace-custom-config';
import css from './RecommendationCriteria.module.css';
import RecommendationPopup from '../../../components/PopUpForms/RecommendationPopup/RecommendationPopup';
import defaultLocations from '../../../default-location-searches';

const RecommendationCriteria = props => {
  const {
    selectedWorktripp,
    worktrippFiltersActive,
    resetWorktrippSearch,
    showWorktrippForm,
    setShowWorktrippForm,
  } = props;
  const { goals = [], venueVibe = [], noOfParticipants = 1 } = selectedWorktripp || {};

  const onCreate = () => {
    setShowWorktrippForm(!showWorktrippForm);
  };

  if (!selectedWorktripp?._id)
    return (
      <div className={css.displayGuidance}>
        <div style={{ marginRight: '40px' }}>
          <div className={css.guidanceTitle}>Looking for guidance?</div>
          <div>Tell us what you’re looking for and get targeted recommendations</div>
        </div>
        <Button
          type="button"
          onClick={() => {
            onCreate();
          }}
          className={css.buttonRecommend}
        >
          <FormattedMessage id="Recommendation.link" />
        </Button>
        <RecommendationPopup
          toggleDisplay={showWorktrippForm}
          setToggleDisplay={setShowWorktrippForm}
        />
      </div>
    );

  return (
    <div className={css.root}>
      {worktrippFiltersActive ? (
        <>
          <div className={css.reccomendationText}>
            Here are your top options based on a WorkTripp
            {selectedWorktripp.location
              ? ` in ${
                  defaultLocations.find(loc => loc.id == selectedWorktripp.location)
                    ?.predictionPlace?.address
                }`
              : ''}{' '}
            for <strong>{noOfParticipants}</strong> people
          </div>
          <div className={css.goalsWrapper}>
            <span>Based on your goals:</span>
            {goals.map(goal => (
              <div key={goal} className={css.goals}>
                {goalsLabels.find(e => e.key == goal)?.title}
              </div>
            ))}
          </div>
        </>
      ) : (
        <p>
          <a onClick={() => resetWorktrippSearch(selectedWorktripp)}>
            Return to previous recommendations
          </a>
        </p>
      )}
    </div>
  );
};

export default RecommendationCriteria;
