import React, { useState } from 'react';
import css from './ListingTypeNavigation.module.css';
import ListingTypeHeader from './ListingTypeHeader/ListingTypeHeader';
import retreats from './retreats.svg';
import venues from './venues.svg';

const ListingTypeNavigation = props => {
  const {
    selectedListingTab,
    changeListingTab,
    totalItems1,
    totalItems2,
    totalItems3,
    totalItems4,
  } = props;

  const switchPage = listingType => {
    if (selectedListingTab !== listingType) {
      changeListingTab(listingType);
    }
  };

  return (
    <div className={css.root}>
      <div className={css.headerWrapper}>
        <ListingTypeHeader
          display="All"
          switchPage={switchPage}
          selectedListingTab={selectedListingTab}
          listingType="all"
          total={totalItems1 + totalItems2 + totalItems3 + totalItems4}
        />
        <ListingTypeHeader
          display="Organisers"
          switchPage={switchPage}
          selectedListingTab={selectedListingTab}
          listingType="retreat"
          icon={retreats}
          total={totalItems4}
        />
        <ListingTypeHeader
          display="Venues"
          switchPage={switchPage}
          selectedListingTab={selectedListingTab}
          listingType="venue"
          icon={venues}
          total={totalItems1}
        />
        <ListingTypeHeader
          display="Workshops"
          switchPage={switchPage}
          selectedListingTab={selectedListingTab}
          listingType="certifiedCoach,domainSpecialistCoach,expertFacilitator,inspiredSpeaker"
          total={totalItems3}
        />
        <ListingTypeHeader
          display="Away Days"
          switchPage={switchPage}
          selectedListingTab={selectedListingTab}
          listingType="experiences"
          total={totalItems2}
        />
      </div>
    </div>
  );
};

export default ListingTypeNavigation;
