import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { SearchMap, ModalInMobile, Page } from '../../components';
import { TopbarContainer } from '../../containers';
import {
  searchMapListings,
  setActiveListing,
  searchListingsByCategory,
  updateFavourites,
  getRecommendedListings,
} from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.module.css';
import defaultLocations from '../../default-location-searches';
import SelectedWorktripp from './SelectedWorktripp/SelectedWorktripp';
import { getWorktrippsList } from '../WorktrippPage/Worktripp.duck';
import { filters } from '../../marketplace-custom-config';
import { attachAuthorAndImagesToListings } from '../../util/misc';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      selectedListingTab: 'all',
      selectedWorktripp: {},
      worktrippFiltersActive: false,
      isCartModalOpen: false,
      showEnquiryForm: false,
      showWorktrippForm: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.changeListingTab = this.changeListingTab.bind(this);
    this.createWorktrippFilterString = this.createWorktrippFilterString.bind(this);
    this.resetWorktrippSearch = this.resetWorktrippSearch.bind(this);
    this.toggleCartModal = this.toggleCartModal.bind(this);
    this.setSelectedWorktripp = this.setSelectedWorktripp.bind(this);
    this.setShowEnquiryForm = this.setShowEnquiryForm.bind(this);
  }

  componentDidMount() {
    const { params, history } = this.props;

    const setCurrentTab = () => {
      const { slug = 'all' } = params;
      this.setState({
        selectedListingTab: slug,
      });
    };

    // retrieve the stored filters from localStorage
    // hint: they are saved each time the user change any of the filters.
    const storedFilters = localStorage.getItem('lastUsedFilter');

    // also the selected worktripp is saved to localStorage
    const selectedWorktripp = localStorage.getItem('tempWorktripp');

    if (selectedWorktripp) {
      // if has a saved worktripp we set it to this class state
      const parsedSelectedWorktripp = JSON.parse(selectedWorktripp);
      this.setState({
        selectedWorktripp: parsedSelectedWorktripp,
      });
    }

    let theSearch = typeof window !== 'undefined' && window.location.search;

    const urlParams = new URLSearchParams(theSearch);
    const getRecommendations = urlParams.get('getRecommendations');
    urlParams.delete('getRecommendations');

    if (getRecommendations == 'true') {
      // if the url has getRecommendations we open the worktripp form
      this.setShowWorktrippForm(true);
    } else if (theSearch) {
      // else we save the filters
      // here where they got saved.
      localStorage.setItem('lastUsedFilter', theSearch);
    } else {
      // or if found stored filters apply them
      if (storedFilters) {
        history.push(storedFilters);
        theSearch = storedFilters;
      }
    }

    // Load worktripps
    const { currentUser, onGetWorktrippList } = this.props;
    if (currentUser) {
      onGetWorktrippList(currentUser.id.uuid);
    }

    // this to set the current tab baseed on the URL
    // the tab refers to a listing category or all of them if all is used.
    setCurrentTab();

    // here we call onSearchListingsByCategory function in the SearchPage.duck.js
    // 4 times once for each category.
    // 0 => venues / 1 => workshops / 2 => experiences / 3 => retreats
    // hint: in the duck file, the numbers are +1 each
    this.props.onSearchListingsByCategory(theSearch, 0);
    this.props.onSearchListingsByCategory(theSearch, 1);
    this.props.onSearchListingsByCategory(theSearch, 2);
    this.props.onSearchListingsByCategory(theSearch, 3);
  }

  componentDidUpdate(prevProps, prevState) {
    const checkWorktrippFilters = () => {
      if (this.state.selectedWorktripp._id) {
        const worktrippFilterString = this.createWorktrippFilterString(
          this.state.selectedWorktripp
        );
        let theSearch = typeof window !== 'undefined' && window.location.search;

        const searchesMatch =
          theSearch?.substring(theSearch.indexOf('?')) ==
          worktrippFilterString?.substring(worktrippFilterString.indexOf('?'));
        this.setState({
          worktrippFiltersActive: searchesMatch,
        });
      }
    };

    if (
      this.props.searchInProgress !== prevProps.searchInProgress ||
      this.state.selectedWorktripp != prevState.selectedWorktripp
    ) {
      if (this.state.selectedWorktripp?._id) {
        checkWorktrippFilters();
      }
    }
    if (
      this.state.selectedWorktripp?.recommendedListings !=
      prevState.selectedWorktripp?.recommendedListings
    ) {
      if (this.state.selectedWorktripp?.recommendedListings.length > 0) {
        this.props.onGetRecommendedListings(this.state.selectedWorktripp?.recommendedListings);
      }
    }

    const { worktripps, currentUser, onGetWorktrippList } = this.props;
    if (worktripps !== prevProps.worktripps) {
      const selectedWorktripp = worktripps.find(worktripp => worktripp.selected) || worktripps[0];
      if (selectedWorktripp) {
        this.setState({
          selectedWorktripp,
        });
      }
      if (currentUser && worktripps.length > 0) {
        localStorage.removeItem('tempWorktripp');
      }
      checkWorktrippFilters();
    }
    if (currentUser !== prevProps.currentUser) {
      if (currentUser) {
        onGetWorktrippList(currentUser.id.uuid);
      }
    }
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes, {
      slug: this.state.selectedListingTab,
    });
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      // we also call all the categories here
      // cause sharetribe uses this function instead of component did update when you move the map,
      const filterString = createResourceLocatorString(
        'SearchPage',
        routes,
        { slug: this.state.selectedListingTab },
        searchParams
      );
      history.push(filterString);
      localStorage.setItem('lastUsedFilter', filterString);
      const theSearch = typeof window !== 'undefined' && window.location.search;
      this.props.onSearchListingsByCategory(theSearch, 0);
      this.props.onSearchListingsByCategory(theSearch, 1);
      this.props.onSearchListingsByCategory(theSearch, 2);
      this.props.onSearchListingsByCategory(theSearch, 3);
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  changeListingTab(listingType) {
    this.setState({
      selectedListingTab: listingType,
    });

    const { history, location, filterConfig } = this.props;

    const routes = routeConfiguration();

    // parse query parameters, including a custom attribute named category
    const { address, bounds, mapSearch, ...rest } = parse(location.search);

    //const viewportMapCenter = SearchMap.getMapCenter(map);
    const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

    const searchParams = {
      address,
      ...originMaybe,
      bounds,
      ...validFilterParams(rest, filterConfig),
    };

    const filterString = createResourceLocatorString(
      'SearchPage',
      routes,
      { slug: listingType },
      searchParams
    );
    history.push(filterString);
  }

  createWorktrippFilterString = selectedWorktripp => {
    const { filterConfig } = this.props;
    const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};
    const routes = routeConfiguration();
    const {
      location,
      venueVibe,
      goals,
      experiences,
      minBudget,
      budgetPerPerson,
      ...rest
    } = selectedWorktripp;

    let mappedGoals = [];
    const goalOptions = filters.find(filter => filter.id == 'goals')?.config.options;

    goals?.forEach(goal => {
      const filteredOptionsForSeletedGoal =
        goalOptions.filter(option => option.parent == goal) || [];
      filteredOptionsForSeletedGoal.forEach(option => mappedGoals.push(option.key));
    });

    const pub_venueVibe = venueVibe;
    const pub_specialism = mappedGoals;
    const pub_listingSector = experiences;
    const priceValue = minBudget || budgetPerPerson?.amount / 100;
    const price = priceValue ? `0,${priceValue}` : '';
    const { address, bounds } =
      defaultLocations.find(defaultLocation => defaultLocation.id == location)?.predictionPlace ||
      {};
    const searchParams = {
      address,
      ...originMaybe,
      bounds: bounds,
      mapSearch: true,
      ...validFilterParams(
        { pub_venueVibe, pub_specialism, pub_listingSector, price },
        filterConfig
      ),
    };
    return createResourceLocatorString(
      'SearchPage',
      routes,
      { slug: this.state.selectedListingTab },
      searchParams
    );
  };

  resetWorktrippSearch = selectedWorktripp => {
    const worktrippFilterString = this.createWorktrippFilterString(selectedWorktripp);
    window.location.href = worktrippFilterString;
  };

  toggleCartModal = value => {
    this.setState({
      isCartModalOpen: value ? value : !this.state.isCartModalOpen,
    });
  };

  setShowEnquiryForm = value => {
    this.setState({
      showEnquiryForm: value ? value : !this.state.showEnquiryForm,
    });
  };

  setShowWorktrippForm = value => {
    const updatedValue = value ? value : !this.state.showWorktrippForm;
    // remove the onLoad auto pop up logic
    if (!updatedValue) {
      let theSearch = typeof window !== 'undefined' && window.location.search;
      const urlParams = new URLSearchParams(theSearch);
      urlParams.delete('getRecommendations');
      this.props.history.push(`?${urlParams.toString()}`);
    }

    this.setState({
      showWorktrippForm: updatedValue,
    });
  };

  setSelectedWorktripp = worktripp => {
    this.setState({
      selectedWorktripp: worktripp,
    });
  };

  render() {
    const {
      intl,
      listings,
      listings4, // retreats
      listings1, // venues
      listings2, // workshops
      listings3, // experiences
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination4, // for retreats
      pagination1, // for venues
      pagination2, // for workshops
      pagination3, // for experiences
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      currentUser,
      currentUserHasListings,
      reccomendedListings,
    } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const selectedListingTypeHasMapIcons =
      this.state.selectedListingTab == 'all' ||
      this.state.selectedListingTab == 'retreat' ||
      this.state.selectedListingTab == 'venue';
    const shouldShowSearchMap = this.state.isSearchMapOpenOnMobile;

    const listingsToShowOnMap = () => {
      // we only show retreats and venues on the map
      switch (this.state.selectedListingTab) {
        case 'retreat':
          return listings4;
        case 'venue':
          return listings1;
        default:
          return mapListings;
      }
    };

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
          tab={this.state.selectedListingTab}
        />
        <div className={css.container}>
          <MainPanel
            urlQueryParams={validQueryParams}
            listings4={listings4}
            listings1={listings1}
            listings2={listings2}
            listings3={listings3}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            searchParamsAreInSync={searchParamsAreInSync}
            onActivateListing={onActivateListing}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={this.onOpenMobileModal}
            onCloseModal={this.onCloseMobileModal}
            onMapIconClick={onMapIconClick}
            pagination4={pagination4}
            pagination1={pagination1}
            pagination2={pagination2}
            pagination3={pagination3}
            searchParamsForPagination={parse(location.search)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            history={history}
            onSearchListingsByCategory={this.props.onSearchListingsByCategory}
            selectedListingTab={this.state.selectedListingTab}
            changeListingTab={this.changeListingTab}
            selectedListingTypeHasMapIcons={selectedListingTypeHasMapIcons}
            isMobileLayout={isMobileLayout}
            selectedWorktripp={this.state.selectedWorktripp}
            worktrippFiltersActive={this.state.worktrippFiltersActive}
            resetWorktrippSearch={this.resetWorktrippSearch}
            currentUser={currentUser}
            isCartModalOpen={this.state.isCartModalOpen}
            toggleCartModal={this.toggleCartModal}
            showWorktrippForm={this.state.showWorktrippForm}
            setShowWorktrippForm={this.setShowWorktrippForm}
            reccomendedListings={reccomendedListings}
          />
          <SelectedWorktripp
            onManageDisableScrolling={onManageDisableScrolling}
            currentUser={currentUser}
            intl={intl}
            isCartModalOpen={this.state.isCartModalOpen}
            toggleCartModal={this.toggleCartModal}
            location={location}
            showEnquiryForm={this.state.showEnquiryForm}
            setShowEnquiryForm={this.setShowEnquiryForm}
          />

          <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={100000}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper}>
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  listings={listingsToShowOnMap() || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              ) : null}
            </div>
          </ModalInMobile>
        </div>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  listings4: [],
  listings1: [],
  listings2: [],
  listings3: [],
  mapListings: [],
  pagination: null,
  pagination4: null,
  pagination1: null,
  pagination2: null,
  pagination3: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  listings4: array,
  listings1: array,
  listings2: array,
  listings3: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  onSearchListingsByCategory: func.isRequired,
  pagination: propTypes.pagination,
  pagination4: propTypes.pagination,
  pagination1: propTypes.pagination,
  pagination2: propTypes.pagination,
  pagination3: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    currentPageResultIds4,
    currentPageResultIds1,
    currentPageResultIds2,
    currentPageResultIds3,
    pagination,
    pagination4,
    pagination1,
    pagination2,
    pagination3,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    reccomendedListings: recommendedListingTemp,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const pageListings0 = getListingsById(state, currentPageResultIds4);
  const pageListings1 = getListingsById(state, currentPageResultIds1);
  const pageListings2 = getListingsById(state, currentPageResultIds2);
  const pageListings3 = getListingsById(state, currentPageResultIds3);

  const reccomendedListings = attachAuthorAndImagesToListings(recommendedListingTemp);
  const mapListings = getListingsById(
    state,
    unionWith(
      [...currentPageResultIds4, ...currentPageResultIds1, ...currentPageResultIds2],
      searchMapListingIds,
      (id1, id2) => id1.uuid === id2.uuid
    )
  );

  const { currentUser, currentUserHasListings } = state.user;

  const { worktripps } = state.Worktripp;

  return {
    listings: pageListings,
    listings4: pageListings0,
    listings1: pageListings1,
    listings2: pageListings2,
    listings3: pageListings3,
    mapListings,
    pagination,
    pagination4,
    pagination1,
    pagination2,
    pagination3,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUser,
    currentUserHasListings,
    worktripps,
    reccomendedListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onSearchListingsByCategory: (s, i, limit) => dispatch(searchListingsByCategory(s, i, limit)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onGetWorktrippList: user_id =>
    dispatch(getWorktrippsList({ user_id, ignoreUpdateProfile: true })),
  onGetRecommendedListings: listingIds => dispatch(getRecommendedListings(listingIds)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
