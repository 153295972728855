import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import { Button, Modal, ContactSupport } from '../..';
import { FormattedMessage } from '../../../util/reactIntl';

import * as validators from '../../../util/validators';
import MultipleEnquiryForm from '../components/MultipleEnquiryForm';
import { sendEnquiry } from '../../../containers/ListingPage/ListingPage.duck';
import { editWorktripp } from '../../../containers/WorktrippPage/Worktripp.duck';

import css from './CheckAvailibilityPopup.module.css';

const mergeListings = (currentListings, newListings) => {
  return currentListings.map(listing => {
    const newListing = newListings.find(l => l.listing_id === listing.listing_id);

    return newListing ?? listing;
  });
};

const CheckAvailibilityPopup = props => {
  const { currentUser, toggleDisplay, setToggleDisplay } = props;

  const dispatch = useDispatch();

  const { selectedWorktripp } = useSelector(state => state.Worktripp);

  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedListingIds, setSelectedListingIds] = useState([]);

  const hideChat = isOpen => {
    const getElement = document.querySelectorAll('.crisp-client');
    getElement.forEach(element => {
      if (!isOpen) element.style.display = 'none';
      else element.style.display = 'block';
    });
  };

  useEffect(() => {
    if (toggleDisplay) {
      setIsOpen(true);
      setToggleDisplay(false);
      setErrorMessage(null);
      hideChat(isOpen);
    }
  }, [toggleDisplay]);

  const notContactedListings = useMemo(() => {
    return (
      selectedWorktripp?.listings?.filter(
        worktrippListing => !worktrippListing.tx_id || worktrippListing.tx_id === 'NOT CONTACTED'
      ) ?? []
    );
  }, [selectedWorktripp?.listings]);

  const allListingsContacted = useMemo(() => notContactedListings.length === 0, [
    notContactedListings,
  ]);

  const showForm = useMemo(() => selectedWorktripp?.listings?.length > 0, [
    selectedWorktripp?.listings,
  ]);

  const onClose = () => {
    setIsSubmit(false);
    setIsOpen(false);
    setSelectedListingIds([]);
  };

  const onSubmit = async values => {
    if (allListingsContacted) {
      onClose();
      return;
    }

    if (checkDisabled(values)) {
      return;
    }

    try {
      setLoading(true);
      const { message } = values;
      const infoToShare = ['companyName', 'workTrippDescription', 'noOfParticipants'];
      const dates = { startDate: selectedWorktripp.startDate, endDate: selectedWorktripp.endDate };

      let txAndListingIds = [];

      const listingPromises = selectedListingIds.map(async listingId => {
        await dispatch(
          sendEnquiry(
            listingId,
            message.trim(),
            dates,
            selectedWorktripp,
            infoToShare,
            currentUser.id.uuid
          )
        )
          .then(async transactionId => {
            console.log('transactionId', transactionId, 'listing', listingId);
            txAndListingIds.push({ listing_id: listingId, tx_id: transactionId.uuid });
          })
          .catch(err => {
            throw err;
          });
      });

      await Promise.all(listingPromises);

      dispatch(
        editWorktripp({
          _id: selectedWorktripp._id,
          listings: mergeListings(selectedWorktripp.listings, txAndListingIds),
        })
      );

      setLoading(false);
      setIsSubmit(true);
    } catch (err) {
      setErrorMessage(err.message);
      setLoading(false);
    }
  };

  const checkDisabled = values => {
    if (loading) {
      return true;
    }
    const { message } = values;

    const multipleEnquiryCheck = !(
      message &&
      selectedListingIds &&
      Object.values(selectedListingIds).some(listing => listing)
    );

    return multipleEnquiryCheck;
  };

  const errorModalPage = () => {
    return (
      <div style={{ padding: 40, textAlign: 'center' }}>
        <h2>Ooops, something went wrong</h2>

        <span> If this issue persists please contact us at </span>
        <ContactSupport subject="Issue with contacting listing" body="CheckAvailibilityPopup" />
      </div>
    );
  };

  const submitModalPage = () => {
    return (
      <div style={{ padding: 40, textAlign: 'center' }}>
        <h1>
          Hooray! <span style={{ fontSize: '64px' }}>🚀</span>
        </h1>
        <h2>Your message has been sent</h2>
        <span>Verify your email to receive a notification when they reply.</span>
        <Button
          type="button"
          onClick={() => {
            window.open(`/worktripps/${selectedWorktripp._id}`, '_top');
          }}
          className={classNames(css.buttonType, css.buttonSubmit)}
        >
          <FormattedMessage id="MarketPopup.viewWorktripp" />
        </Button>
      </div>
    );
  };

  const formModalPage = () => {
    return (
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{
          message: `Hello!
          
I\’m interested in your listing for our next offsite. Could you tell me your availability and pricing for our dates? 

Thanks!`,
        }}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <h1 style={{ textAlign: 'center', fontSize: '30px', marginTop: '24px' }}>
                Send new enquiries
              </h1>
              <hr style={{ borderBottom: '1px solid #bbb' }}></hr>
            </div>
            <div className={css.formBody}>
              <MultipleEnquiryForm
                validators={validators}
                selectedListingIds={selectedListingIds}
                setSelectedListingIds={setSelectedListingIds}
                currentUser={currentUser}
              />
            </div>
            {errorMessage && (
              <div style={{ color: 'red', marginTop: '24px', marginBottom: '24px' }}>
                {errorMessage}
              </div>
            )}
            <div className={css.setBottomFlex}>
              <Button
                type="submit"
                className={css.buttonType}
                style={{ marginLeft: '1rem', width: '100%', padding: '0px 20px' }}
                disabled={allListingsContacted ? false : checkDisabled(values)}
              >
                {allListingsContacted ? (
                  'Browse more listings'
                ) : (
                  <FormattedMessage id="SendMessageForm.sendMessage" />
                )}
              </Button>
            </div>
          </form>
        )}
      />
    );
  };

  if (!showForm) {
    return <div />;
  }

  return (
    <Modal
      id="CheckAvailibilityPopup"
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={() => {}}
      closeButtonMessage="hidden"
      containerClassName={css.container}
    >
      {isSubmit ? (errorMessage ? errorModalPage() : submitModalPage()) : formModalPage()}
    </Modal>
  );
};

export default CheckAvailibilityPopup;
