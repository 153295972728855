import React, { useEffect, useMemo, useState } from 'react';
import { IoSwapHorizontal } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import { FaHeart } from 'react-icons/fa';

import {
  Button,
  IconSpinner,
  InlineTextButton,
  Modal,
  ModalInMobile,
  NamedLink,
  SecondaryButton,
  SelectedListingCard,
  WorktrippBasicInfo,
  WorktrippBookGuide,
} from '../../../components';

import { useDispatch, useSelector } from 'react-redux';
import { selectWorktripp, removeListingFromWorktripp } from '../../WorktrippPage/Worktripp.duck';

import CheckAvailibilityPopup from '../../../components/PopUpForms/CheckAvailibilityPopup/CheckAvailibilityPopup';
import SignUpFormPopUp from '../../../components/PopUpForms/SignUpFormPopUp/SignUpFormPopUp';
import { RESET_ACTION_AFTER_LOGIN } from '../../../ducks/Auth.duck';

import css from './SelectedWorktripp.module.css';

const SelectedWorktripp = props => {
  const {
    onManageDisableScrolling,
    currentUser,
    intl,
    isCartModalOpen,
    toggleCartModal,
    location,
    showEnquiryForm,
    setShowEnquiryForm,
  } = props;

  const dispatch = useDispatch();
  const {
    SearchPage: searchPageReducer,
    Worktripp: worktrippReducer,
    Auth: authReducer,
  } = useSelector(state => state);
  const { getFavouritesInProgress, updateFavouritesInProgress } = searchPageReducer;
  const { worktripps, selectedWorktripp, worktrippListings } = worktrippReducer;
  const { actionAfterLogin } = authReducer;

  const [loading, setLoading] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const isLoggedIn = currentUser?.id?.uuid;

  useEffect(() => {
    if (!updateFavouritesInProgress) {
      setLoading(updateFavouritesInProgress);
    }
  }, [getFavouritesInProgress]);

  useEffect(() => {
    if (actionAfterLogin == 'showEnquiryForm') {
      setShowSignUpForm(false);
      setShowEnquiryForm(true);

      dispatch({
        type: RESET_ACTION_AFTER_LOGIN,
      });
    }
  }, [actionAfterLogin]);

  const handleSelectModalOpen = () => {
    setIsSelectModalOpen(true);
  };

  const onRemoveListingFromFavourites = listingId => {
    if (!currentUser) {
      return;
    }

    const dispatchParams = { worktripp: selectedWorktripp, listingId: listingId };
    dispatch(removeListingFromWorktripp(dispatchParams));
  };

  const onSelectWorktripp = selectedId => {
    const allIds = worktripps.map(w => w._id).filter(id => id !== selectedId);
    dispatch(selectWorktripp({ selectedId, allIds }));
  };

  const renderCurrentWorktripp = () => {
    if (!selectedWorktripp || Object.keys(selectedWorktripp).length === 0) {
      return <h2 className={css.worktrippTitle}>Start your WorkTripp</h2>;
    }
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NamedLink
            className={css.link}
            name="ViewWorktripp"
            params={{ id: selectedWorktripp?._id }}
          >
            <h2 className={css.worktrippTitle}>
              {selectedWorktripp.name || selectedWorktripp.title}
            </h2>
          </NamedLink>
          <NamedLink name={'EditWorktripp'} params={{ id: `${selectedWorktripp._id}` }}>
            <MdModeEditOutline size={24} style={{ marginLeft: 16 }} />
          </NamedLink>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 16,
          }}
        >
          {renderSelectWorkTrippModal()}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 20,
          }}
        >
          <WorktrippBasicInfo worktripp={selectedWorktripp} isViewOnly={false} />
        </div>
        <div className={css.underline} />
      </div>
    );
  };

  const renderSelectWorkTrippModal = () => {
    if (worktripps.length <= 1) {
      return null;
    }

    const onSelect = wt => {
      setLoading(true);
      onSelectWorktripp(wt._id);
      setIsSelectModalOpen(false);
      setLoading(false);
    };

    return (
      <div>
        <InlineTextButton onClick={handleSelectModalOpen}>
          <IoSwapHorizontal size={18} style={{ margin: 5 }} />
          Change selected WorkTripp
        </InlineTextButton>
        <Modal
          {...props}
          id="WorktripSelector"
          isOpen={isSelectModalOpen}
          onClose={() => {
            setIsSelectModalOpen(false);
          }}
        >
          <div style={{ margin: '1rem', textAlign: 'center' }}>
            <h1>Select Worktripp</h1>
            {worktripps.map(wt => {
              const ButtonComponent = wt?._id === selectedWorktripp?._id ? Button : SecondaryButton;
              return (
                <ButtonComponent
                  key={wt._id}
                  onClick={() => onSelect(wt)}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  {wt.name}
                </ButtonComponent>
              );
            })}
          </div>
        </Modal>
      </div>
    );
  };

  const renderListingsShortlist = () => {
    if (!selectedWorktripp?.listings || selectedWorktripp.listings.length < 1) {
      return <div />;
    }

    return (
      <div>
        <div className={css.listingsWrapper}>
          {selectedWorktripp.listings.map(({ listing_id }) => {
            const listing = worktrippListings[listing_id];

            return (
              <SelectedListingCard
                key={listing_id}
                listing={listing}
                intl={intl}
                onRemoveListingFromFavourites={onRemoveListingFromFavourites}
              />
            );
          })}
        </div>
        <div>
          <Button
            className={css.enquireButton}
            onClick={() => (!isLoggedIn ? setShowSignUpForm(true) : setShowEnquiryForm(true))}
          >
            Check availability and pricing
          </Button>
          {!currentUser && (
            <p style={{ textAlign: 'center' }}>
              In order to book, please <a href="/login">login</a> or{' '}
              <a onClick={() => setShowSignUpForm(true)}>create an account</a> to check supplier
              availability and pricing.{' '}
            </p>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className={css.spinnerContainer}>
        <IconSpinner className={css.spinner} />
      </div>
    );
  }

  return (
    <div>
      <ModalInMobile
        className={css.cartShadow}
        containerClassName={css.cartPanelWrapper}
        id="SearchPage.selectedWorktripp"
        isModalOpenOnMobile={isCartModalOpen}
        onClose={() => toggleCartModal(false)}
        showAsModalMaxWidth={768}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div style={{ paddingBottom: 40 }}>
          {renderCurrentWorktripp()}
          {renderListingsShortlist()}
        </div>
        <WorktrippBookGuide horizontal />
      </ModalInMobile>
      <CheckAvailibilityPopup
        currentUser={currentUser}
        toggleDisplay={showEnquiryForm}
        setToggleDisplay={setShowEnquiryForm}
      />
      <SignUpFormPopUp
        currentUser={currentUser}
        toggleDisplay={showSignUpForm}
        setToggleDisplay={setShowSignUpForm}
        selectedWorktripp={selectedWorktripp}
        location={location}
      />
    </div>
  );
};

export default SelectedWorktripp;
